import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
import { ISlides } from "./CarouselHeroSlides"
import { css } from "@emotion/react"

interface SlideTextProps {
  slide: ISlides
}

const SlideText: React.FC<SlideTextProps> = ({
  slide: { headline, headlineTwo, headlineThree, description },
}) => {
  const [text, setText] = useState({
    headline,
    headlineTwo,
    headlineThree,
    description,
  })
  const { language } = useContext(LanguageContext)
  useEffect(() => {
    if (language === "en") {
      setText({ headline, headlineTwo, headlineThree, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          headline,
          headlineTwo,
          headlineThree,
          description,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language, headline, headlineTwo, headlineThree, description])

  return (
    <>
      {headline && (
        <h1
          css={[
            tw`text-2xl lg:text-5xl font-bold pt-5 md:pt-0 pb-1 sm:pb-3`,
            css`
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
              margin-top: 0; // Default margin
              @media (min-width: 1280px) {
                // Apply on xl and larger breakpoints
                margin-top: -20vh;
              }
            `,
          ]}
        >
          {text?.headline}
          {headlineTwo && (
            <>
              <br />
              {text?.headlineTwo}
            </>
          )}
          {headlineThree && (
            <>
              <br />
              {text?.headlineThree}
            </>
          )}
        </h1>
      )}

      {description && (
        <h2
          css={[
            tw`pb-2 font-semibold tracking-wide text-lg md:text-xl lg:text-2xl`,
          ]}
          style={{ textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)" }}
        >
          {text?.description}
        </h2>
      )}
    </>
  )
}
export default SlideText
