import React, { useState } from "react"
import tw from "twin.macro"
import Button from "../Button/Button"

import {
  Pagination,
  Navigation,
  Autoplay,
  EffectFade,
  Swiper as ISwiper,
} from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import CarouselButton from "../Carousel/CarouselButton"
import { getImage } from "gatsby-plugin-image"
import MenuIcons from "../MenuIcons/MenuIcons"
import { css } from "@emotion/react"
import SlideText from "./SlideText"

export interface ISlides {
  cta: {
    title: string
    url: string
  }
  headline: string
  headlineTwo: string
  headlineThree: string
  description: string
  textAlignment: "left" | "center" | "right"
  textColor: "white" | "black"
  backgroundImage: {
    sourceUrl: string
    localFile: {
      publicURL: string
    }
  }
}

interface ICarousel {
  slides: ISlides[]
}

const HeroCarouselSlides: React.FC<ICarousel> = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [swiper, setSwiper] = useState<ISwiper>()

  const slideLeft = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  const slideRight = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  const slideStyle = css`
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 2;
    }
  `

  const textAlignmentStyles = {
    left: tw`text-center sm:text-left`,
    center: tw`text-center`,
    right: tw`text-center sm:text-right`,
  }

  return (
    <div className="pb-3">
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        effect={"fade"}
        navigation={true}
        spaceBetween={50}
        slidesPerView={1}
        loop={false}
        onSwiper={swiper => setSwiper(swiper)}
        onActiveIndexChange={swiper => setActiveIndex(swiper?.activeIndex)}
        className="carousel"
        css={[tw`lg:mb-0 mb-10`]}
      >
        {slides?.map((slide, index) => {
          const backgroundImageSrc = getImage(slide?.backgroundImage?.localFile)
          if (!backgroundImageSrc) return

          // const words = slide?.headlineTwo.split(" ")
          // if (words.length <= 3) {
          //   return slide?.headlineTwo
          // }

          return (
            <SwiperSlide
              className="hero-home-carousel"
              key={`hero-home-carousel-${slide}-${index}`}
            >
              <div
                css={[
                  tw`w-full pb-[100%] sm:pb-[90%] md:pb-[56.25%] xl:pb-[73vh] background-size[cover] background-position[center] background-repeat[no-repeat] bg-no-repeat bg-cover `,
                  slideStyle,
                  {
                    backgroundImage: `url('${slide?.backgroundImage?.localFile?.publicURL}')`,
                  },
                ]}
              >
                <div
                  css={[
                    tw`z-10 mx-auto absolute top-0 left-0 right-0 bottom-0 w-full h-full flex flex-col p-5 md:p-10 lg:px-40 xl:px-48 2xl:px-60 justify-center`,

                    {
                      color: slide?.textColor ?? "white",
                    },
                    textAlignmentStyles[
                      slide.textAlignment as keyof typeof textAlignmentStyles
                    ],
                  ]}
                >
                  <SlideText slide={slide} />

                  {slide?.cta?.url && slide?.cta?.title && (
                    <div>
                      <Button
                        css={[tw`text-sm lg:text-base mt-4`]}
                        ariaLabel={`${slide.cta.title}`}
                        link={slide.cta?.url}
                        text={slide.cta?.title}
                      />
                    </div>
                  )}

                  {slides?.length > 1 && (
                    <CarouselButton
                      firstIndex={activeIndex === 0}
                      lastIndex={activeIndex === slides?.length - 1}
                      slideLeft={slideLeft}
                      slideRight={slideRight}
                    />
                  )}
                </div>
              </div>
            </SwiperSlide>
          )
        })}
        <div
          css={[
            tw`flex flex-row justify-center gap-12 lg:gap-20 overflow-hidden items-start py-5 lg:py-10 px-6 bg-blue-250`,
          ]}
        >
          <MenuIcons
            styling={tw`w-20 md:w-32 text-xs md:text-sm xl:text-xl first-of-type:ml-0`}
          />
        </div>
      </Swiper>
    </div>
  )
}

export default HeroCarouselSlides
