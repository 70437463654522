import React from "react"
import { graphql } from "gatsby"
import { HomePage } from "../templates/templates"
import HomeSearch from "../components/HomeSearch/HomeSearch"
import HomeCarousel from "../components/HomeCarousel/HomeCarousel"
import HomeHero from "../components/Hero/HomeHero"
import HomeInsideAirport from "../components/HomeInsideAirport/HomeInsideAirport"
import HomeBusiness from "../components/HomeBusiness/HomeBusiness"
import tw from "twin.macro"
import { SEO } from "../components/SEO"
import CarouselHero from "../components/Hero/CarouselHero"

const Home: React.FC<HomePage> = ({ data, location }) => {
  const [hasVideo, setHasVideo] = React.useState(false)

  React.useEffect(() => {
    if (data) {
      setHasVideo(data?.wpPage?.homePage?.hero?.videoFileUrl?.mediaItemUrl)
    }
  }, [data])

  return (
    <>
      <SEO url={location.href} />
      <section css={[tw`w-full`]}>
        {hasVideo ? (
          <HomeHero data={data?.wpPage?.homePage?.hero} />
        ) : (
          <CarouselHero
            heroCarouselSlides={data?.wpPage?.homePage?.heroCarouselSlides}
          />
        )}
        <HomeSearch />
        <HomeCarousel
          carouselSlides={data?.wpPage?.homePage?.carouselSlides}
          highlightTextHeading={data?.wpPage?.homePage?.highlightTextHeading}
        />
        <HomeInsideAirport
          headline={data?.wpPage?.homePage?.imageLinksSection?.headline}
          cards={data?.wpPage?.homePage?.imageLinksSection?.imageLink}
        />
        <HomeBusiness
          cards={data?.wpPage?.homePage?.cardsWithIconsSection?.cardWithIcon}
          headline={data?.wpPage?.homePage?.cardsWithIconsSection?.headline}
        />
      </section>
    </>
  )
}

export const query = graphql`
  query {
    wpPage(isFrontPage: { eq: true }) {
      homePage {
        highlightTextHeading
        imageLinksSection {
          headline
          imageLink {
            link {
              target
              title
              url
            }
            backgroundImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
        hero {
          crown
          description
          headline
          cta {
            target
            title
            url
          }
          videoFileUrl: videoFile {
            mediaItemUrl
          }
          heroPosterImageUrl: heroPosterImage {
            mediaItemUrl
          }
        }
        heroCarouselSlides {
          headline
          headlineTwo
          headlineThree
          textAlignment
          textColor
          description
          cta {
            target
            title
            url
          }
          backgroundImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          backgroundImage {
            sourceUrl
            altText
            fileSize
            mediaDetails {
              height
              width
            }
          }
        }
        carouselSlides {
          header
          cta {
            title
            url
            target
          }
          backgroundImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          foregroundImage {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
          }
        }
        cardsWithIconsSection {
          headline
          cardWithIcon {
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
            description
            cta {
              target
              title
              url
            }
            header
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Home
